import { Response } from './../../../models/response.model';
import { FormValidator } from 'src/app/shared/validators/form.validator';
import { Token } from './../../../models/token.model';
import { CepService } from './../../../services/cep.service';
import { AuthBaseService } from './../../../services/auth/auth-base.service';
import { UsuarioCadastro } from './../../../models/user/user-register-base.model';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Cep } from 'src/app/models/cep.model';
import * as moment from 'moment';



@Component({
    selector: 'app-signup-modal',
    templateUrl: './signup-modal.component.html',
    styleUrls: ['./signup-modal.component.scss']
})
export class SignUpModalComponent implements OnInit {
    isDisabledFlag: boolean = false;
    error: string;
    signupForm: FormGroup;
    isCep: boolean = false;
    public isLoading = false;

    isCepLoading: boolean = false;


    constructor(
        public dialogRef: MatDialogRef<SignUpModalComponent>,
        private formValidators: FormValidator,
        private authBaseService: AuthBaseService,
        private cepService: CepService
    ) { }

    ngOnInit(): void {
        this.signupForm = new FormGroup({
            cpf: new FormControl(null, Validators.compose([Validators.required, this.formValidators.cpfValidator, Validators.minLength(11)])),
            nome: new FormControl(null, [Validators.required, this.formValidators.nameValidator]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            dataNascimento: new FormControl(null, [Validators.required, this.formValidators.dateValidator]),
            sexo: new FormControl('F'),
            // senha: new FormControl(null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'), Validators.minLength(8)]),
            senha: new FormControl(null, [Validators.required, Validators.minLength(6)]),
            confSenha: new FormControl(null, [Validators.required, Validators.minLength(6)]),
            telefone: new FormControl(null, Validators.required),
            cep: new FormControl(null, Validators.required),

            logradouro: new FormControl(null, [Validators.required]),
            cidade: new FormControl(null, [Validators.required]),
            estado: new FormControl(null, [Validators.required]),
            bairro: new FormControl(null, [Validators.required]),
            complemento: new FormControl(null, null),
            numero: new FormControl(null, [Validators.required]),
        }, { validators: this.formValidators.confirmPassword });

        this.signupForm.controls['logradouro'].disable();
        this.signupForm.controls['cidade'].disable();
        this.signupForm.controls['estado'].disable();
        this.signupForm.controls['bairro'].disable();
    }

    redirectToLogin() {
        this.dialogRef.close({ openModalLogin: true });
    }

    getCep(): void {
        if (this.signupForm.get('cep').value !== null && this.signupForm.get('cep').value.length === 8) {
            this.isCepLoading = true;
            this.cepService.getCep(this.signupForm.get('cep').value).subscribe((res: Response<Cep>) => {

                if(res.retorno){
                    this.signupForm.get('logradouro').setValue(res.data.logradouro.descricao)
                    this.signupForm.get('cidade').setValue(res.data.localidade.descricao)
                    this.signupForm.get('estado').setValue(res.data.uf)
                    this.signupForm.get('bairro').setValue(res.data.bairro.descricao)

                    // Exibindo divs restritas com informações do CEP
                    document.getElementById('cepRestrict01').style.display = 'block';
                    document.getElementById('cepRestrict02').style.display = 'block';
                    document.getElementById('cepRestrict03').style.display = 'block';
                    document.getElementById('cepRestrict04').style.display = 'block';
                    document.getElementById('cepRestrict05').style.display = 'block';
                    document.getElementById('cepRestrict06').style.display = 'block';
                    this.isCep = true;
                } else {
                    this.signupForm.get('cep').setErrors({ 'notFound': true })
                }
                this.isCepLoading = false;
            }, () => {
                this.signupForm.get('cep').setErrors({ 'invalid': true })
                this.isCepLoading = false;
            })
        }
    }

    signup() {
        if (this.signupForm.valid) {

            console.log(this.formToModel());

            this.validateCPF().then(() => {
                if (this.signupForm.valid) {

                    if (!this.isLoading) {
                        this.isLoading = true;

                        this.isDisabledFlag = true;

                        this.authBaseService.signup(this.formToModel()).subscribe((response: Response<Token>) => {
                            this.isLoading = false;

                            if (!response.retorno) {
                                this.error = response.message;
                                this.isDisabledFlag = false;
                            } else {
                                this.dialogRef.close({isLoggedIn: true});
                                this.authBaseService.setToken(response.data);

                                this.isDisabledFlag = false;
                            }
                        });
                    }
                }
            });
        } else {
            this.validateFields();
        }
    }

    formToModel(): UsuarioCadastro {
        let dataNascimento = this.signupForm.value.dataNascimento.split('/');
        //let dataNascimentoDate = new Date(dataNascimento[1] + ',' + dataNascimento[0] + ',' + dataNascimento[2]);
        let dataNascimentoDate = moment(dataNascimento[2] + '-' + dataNascimento[1] + '-' + dataNascimento[0]);

        return {
            cpf: this.signupForm.value.cpf,
            nome: this.signupForm.value.nome,
            email: this.signupForm.value.email,
            //dataNascimento: dataNascimentoDate.getFullYear() + '-' + (dataNascimentoDate.getMonth() + 1) + '-' + dataNascimentoDate.getDate(),
            dataNascimento: dataNascimentoDate.year() + '-' + (dataNascimentoDate.month() + 1) + '-' + dataNascimentoDate.date(),

            sexo: this.signupForm.value.sexo,
            senha: this.signupForm.value.senha,
            logradouro: this.signupForm.value.logradouro,
            cidade: this.signupForm.value.cidade,
            estado: this.signupForm.value.estado,
            numero: this.signupForm.value.numero,
            bairro: this.signupForm.value.bairro,
            complemento: this.signupForm.value.complemento,
            confSenha: this.signupForm.value.confSenha,
            telefone: this.signupForm.value.telefone,
            cep: this.signupForm.value.cep,
        }
    }

    closeModal() {
        this.dialogRef.close();
    }

    validateCPF() {
        return new Promise((resolve) => {
            this.authBaseService.validateCPF(this.signupForm.value.cpf).subscribe((exists: Response<string>) => {
                if (exists.data == 'true') {
                    this.signupForm.controls['cpf'].setErrors({ exists: true });
                } else if (this.signupForm.controls['cpf'].hasError('cpfInvalido')) {
                    this.signupForm.controls['cpf'].setErrors({ ...this.signupForm.get('cpf').errors });
                }
                else {
                    this.signupForm.controls['cpf'].setErrors(null);
                }
                resolve(true);
            });
        });
    }

    validateEmail() {
        return new Promise((resolve) => {
            this.authBaseService.validateEmail(this.signupForm.value.email).subscribe((exists: Response<boolean>) => {
                if (exists.data == true) {
                    this.signupForm.controls['email'].setErrors({ ...this.signupForm.get('email').errors, exists: true });
                } else if (this.signupForm.controls['email'].hasError('emailInvalido')) {
                    this.signupForm.controls['email'].setErrors({ ...this.signupForm.get('email').errors });
                } else {
                    this.signupForm.controls['email'].setErrors(null);
                }
                resolve(true);
            });
        });
    }

    validateFields() {
        this.signupForm.markAllAsTouched();
    }

    isDisabled() {
        return this.isDisabledFlag;
    }
}
