import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { PopupSuccessModalComponent } from './shared/components/popup-success-modal/popup-success-modal.component';
import { Router, RoutesRecognized } from '@angular/router';
import { PopupService } from './services/popup.service';
import { Popup } from './models/popup.model';
import { Response } from './models/response.model';
import { PopupModalComponent } from './shared/components/popup-modal/popup-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  fullhd = window.innerWidth > 1279;
  isMobile = window.innerWidth < 960;
  isPopupOpened: any;

  popupList: Popup[] = [];

  popupSubs: Subscription = new Subscription()

  constructor(
    private dialog: MatDialog,
    private popupService: PopupService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getPopups();
    this.watchUrl();
  }

  watchUrl(): void {
    this.router.events.subscribe((res) => {
      if (res instanceof RoutesRecognized) {
        this.openPopup(res.urlAfterRedirects.substring(1));
      }
    })
  }

  getPopups(): void {
    this.popupSubs.unsubscribe();
    this.popupSubs = this.popupService.getPopups('buscarTodos').subscribe((res: Response<Popup[]>) => {
      this.popupList = res.data;

      let url = window.location.pathname.substring(1).replace('polodelideranca/', '');

      this.openPopup(url);
    })
  }

  openPopup(url: string): void {
    for (let i = 0; i < this.popupList.length; i++) {

      if (url === this.popupList[i].url && this.popupList[i].ativo) {

        if (this.popupList[i].popupCampos !== null && this.popupList[i].popupCampos.length > 0) {
          this.isPopupOpened = this.dialog.open(PopupModalComponent, {
            maxWidth: this.isMobile ? '100vw' : '',
            width: this.isMobile ? '100%' : '50%',
            height: this.isMobile ? '85%' : '',
            panelClass: ['no-padding-modal', 'rounded-border-modal'],
            data: new Popup(this.popupList[i]),
            scrollStrategy: new NoopScrollStrategy(),
            autoFocus: false,
          });

          this.isPopupOpened.afterClosed().subscribe((res: { data: Popup }) => {
            if (res && res.data) {
              this.openDownloadModal(this.popupList[i]);
            }
          })
        } else {
          this.openDownloadModal(this.popupList[i]);
        }

      }

    }

  }

  openDownloadModal(popup: Popup): void {
    this.dialog.open(PopupSuccessModalComponent,
      {
        width: '50%',
        panelClass: ['no-padding-modal', 'rounded-border-modal'],
        data: new Popup(popup)
      }
    )
  }

}
