import { Book } from './../models/book.model';
import { Response } from '../models/response.model';
import { AuthBaseService } from './auth/auth-base.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(
    protected http: HttpClient,
    protected authBaseService: AuthBaseService
  ) {
  }

  getRecomended(id: number): Observable<Response<Book[]>> {
    return this.http.get<Response<Book[]>>(environment.apiEndpoint + '/livros/by-tags-diagnostico/' + id);
  }

}