import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class PopupService extends BaseService {
  modelName = 'popup';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getPopups(str: string) {
    return this.http.get(this.urlApi + '/' + this.modelName + '/' + str);
  }
}