import { Component, Input, OnInit } from '@angular/core';
import { mixinDisableRipple } from '@angular/material/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() txt: string = null;
  @Input() btnClass: string = 'btn-white';
  @Input() fontFamily: string = 'Osvald';
  @Input() fontWeight: string = '700';
  @Input() fontSize: string;
  @Input() txtRight: string;

  @Input() href: string;

  @Input() disableRipple: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
}