<div fxLayout="column">

    <div>
        <span (click)="dismiss()" class="material-icons pointer" fxLayoutAlign="end" style="margin: 1%; color: red;">
            close
        </span>
    </div>

    <div fxLayout="column" fxLayoutGap="59px" fxLayoutAlign="center">
        <div fxLayout="column" fxLayoutGap="17px" fxLayoutAlign="center center">
            <span class="title" *ngIf="data.popupCampos && data.popupCampos.length > 0">Obrigado</span>
            <span class="subtitle" *ngIf="!data.popupCampos || data.popupCampos.length == 0">{{data.mensagem}}</span>
        </div>

        <a (click)="redirecionar()" class="download-btn pointer" *ngIf="link"> Clique aqui para acessar </a>

        <a (click)="download()" *ngIf="fileName && fileName != 'null'" class="download-btn pointer">Arquivo para
            download</a>

        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="data.pathAnexo">
            <app-button fxFlex="30" [txt]="'Fechar'" (click)="dismiss()"
                [btnClass]="'btn-blue-filled-no-animation popup-padding popup-style'" [fontFamily]="'Montserrat'">
            </app-button>
        </div>

        <div></div>
    </div>
</div>