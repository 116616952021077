import { EResultStatus } from 'src/app/enums/result-status.enum';
import { ResultModalComponent } from './../result-modal/result-modal.component';
import { Response } from './../../../models/response.model';
import { FormValidator } from 'src/app/shared/validators/form.validator';

import { Token } from './../../../models/token.model';
import { AuthBaseService } from './../../../services/auth/auth-base.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
    user = null;
    mobile = window.innerWidth < 960;

    error: string;
    success: boolean;
    public isLoading = false;

    isForgotPassword: boolean = false;

    loginForm = new FormGroup({
        login: new FormControl(null, [Validators.required, this.formValidators.cpfValidator, Validators.minLength(11)]),
        password: new FormControl(null, Validators.required)
    });

    forgotForm = new FormGroup({
        login: new FormControl(null, Validators.required),
    });

    constructor(
        public dialogRef: MatDialogRef<LoginModalComponent>,
        private authBaseService: AuthBaseService,
        private _snackBar: MatSnackBar,
        private formValidators: FormValidator,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {

    }

    redirectToSignUp() {
        this.dialogRef.close({ openModalSignUp: true });
    }

    submit() {
        this.error = null;
        this.success = false;

        if (!this.isForgotPassword) {
            if (this.loginForm.valid) {
                this.isLoading = true;

                this.authBaseService.login(this.loginForm.value).subscribe((res: Response<Token>) => {
                    if (res.retorno) {
                        this.authBaseService.setToken(res.data);
                        this.dialogRef.close({isLoggedIn: true});
                    } else {
                        this.isLoading = false;
                        this.error = "Usuário ou senha incorretos!";
                    }
                }, (err) => {

                });
            }
        } else {

            if (this.forgotForm.valid) {

                this.authBaseService.forgotPassword(this.forgotForm.value.login).subscribe((response) => {
                    let respAux = response as any;

                    if (respAux.retorno) {
                        this.dialogRef.close();

                        this.dialog.open(ResultModalComponent, {
                            //maxWidth: '500px',
                            panelClass: "round-border-modal",
                            data: <any>{
                                status: EResultStatus.SUCCESS,
                                message: response,
                            }
                        });
                    } else {
                        this.error = respAux.message;
                    }
                });
            }
        }
    }

    validateFields() {
        this.forgotForm.markAllAsTouched();
    }

    toggleForgotPassword(tipo) {
        this.isForgotPassword = tipo;
    }

    closeModal() {
        this.dialogRef.close();
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }
}

