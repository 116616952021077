import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LoadingSpinnerComponent } from './../../components/loading-spinner/loading-spinner.component';
import { SafePipe } from './../../pipes/safe.pipe';
import { ButtonComponent } from './../../components/button/button.component';
import { FontAwesomeBaseModule } from './../../../fontawesome-base/fontawesome-base.module';

import { AuthGuard } from './../../../guards/auth.guard';
import { HttpCustomInterceptor } from './../../interceptors/http.interceptor';
import { UtilService } from './../../../services/util.service';
import { AlertService } from './../../../services/alert.service';
import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialBaseModule } from '../material/material-base/material-base.module';
import { EmbedVideo } from 'ngx-embed-video';
import { SharerMethod } from 'ngx-sharebuttons';

@Injectable()
@NgModule({
  declarations: [
    ButtonComponent,
    SafePipe,
    LoadingSpinnerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    EmbedVideo.forRoot(),
    ReactiveFormsModule,
    FlexLayoutModule.withConfig({ ssrObserveBreakpoints: ["gt-sm", "lt-md"] }),
    ToastrModule.forRoot(),
    MaterialBaseModule,
    FontAwesomeBaseModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    AuthGuard,
    AlertService,
    UtilService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCustomInterceptor,
      multi: true,
    },
  ],
  exports: [
    ButtonComponent,
    SafePipe,
    LoadingSpinnerComponent,

    EmbedVideo,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ToastrModule,
    MaterialBaseModule,
    FontAwesomeBaseModule,
    NgxMatSelectSearchModule
  ]
})
export class BaseModule { }
