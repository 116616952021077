<div fxLayout="row" class="background-color" fxHide.lt-md id="header">
    <div class="margin" fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
        <div fxLayout="row" style="width: 100%;">
            <div fxLayout="row" fxLayoutGap="55px" fxLayoutGap.lt-xl="30px">
                <a href="https://www.sebrae.com.br/" target="_blank"><img class="pointer"
                        src="./assets/img/logoSebraeBranco.png"></a>
                <img src="./assets/img/logoPoloBranca.png" class="pointer" routerLink="/home">
            </div>


            <div fxLayout="row"  fxLayoutGap="30px" class="options" fxLayoutGap.lt-xl="10px" style="width: 100%;">
                <mat-menu #products="matMenu" overlapTrigger="false">
                <span>
                    <button class="submenu" mat-menu-item routerLink="/products/digital">DIGITAIS</button>
                    <button class="submenu" mat-menu-item routerLink="/products/presencial">PRESENCIAIS</button>
                </span>
                </mat-menu>

                <mat-menu #redeLideres="matMenu" overlapTrigger="false">
                <span>
                    <button class="submenu" mat-menu-item routerLink="" (click)="goToLink('http://comunidade.polodeliderancasebrae.com.br/')">COMUNIDADE DIGITAL</button>
                    <button class="submenu" mat-menu-item routerLink="" (click)="goToLink('./forum')">FÓRUM</button>
                </span>
                </mat-menu>
                <!-- FIM DA IMPLEMENTAÇÃO -->

                <mat-menu #user="matMenu">
                    <a mat-menu-item (click)="goToLink(environment.AREA_LOGADA_OLD + 'admin/artigos')">Meu Polo</a>
                    <a mat-menu-item (click)="logout()">Sair</a>
                </mat-menu>

                <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                    <div fxLayout="row" fxLayoutGap="30px">
                        <a class="optMenu menu-selected" id="home"  backdropClass="custom__menu"  routerLink="/home"  >HOME</a>
                        <a class="optMenu" id="sobre" routerLink="/sobre"  >SOBRE O POLO</a>
                        <a class="optMenu pointer" id='produto'  [matMenuTriggerFor]="products" >CURSOS E PROGRAMAS</a>


                        <a class="optMenu" id='lastArticles' routerLink="/articles/last-articles">ARTIGOS</a>
                        <a class="optMenu pointer" id='redeLideres' [matMenuTriggerFor]="redeLideres"  >REDE DE LÍDERES</a>
                        <a class="optMenu" id='leadershipTest' routerLink="/leadership-test" >AUTOAVALIAÇÃO</a>
                    </div>
                    <div>
                        <a class="pointer optMenu" id="usuario"  *ngIf="currentLoggedUser" [matMenuTriggerFor]="user"
                            style="text-decoration: underline;">Olá
                            {{currentLoggedUser.usuario.nome}}</a>
                    </div>
                </div>
            </div>
        </div>
        
        <div fxLayout="row" fxLayoutGap="30px" fxLayoutGap.lt-xl="15px" *ngIf="!currentLoggedUser"
            fxLayoutAlign="center center">
            <app-button [txt]="'LOGIN'" [btnClass]="'btn-transparent-white btn-header-size'" [fontWeight]="'900'"
                (click)="openLoginModal()" [fontFamily]="'Museo Sans'"></app-button>
            <app-button [txt]="'CADASTRE-SE'" [btnClass]="'btn-metalic btn-header-size'" [fontWeight]="'900'"
                (click)="openSignupModal()" [fontFamily]="'Museo Sans'"></app-button>
        </div>
    </div>
</div>

<mat-sidenav-container fxHide.gt-sm style="height: 100%;width: 100%; position: static;">
    <!-- main side nav -->
    <mat-sidenav #right class="menu-portal" position="end" (click)="right.toggle()" style="position: fixed;">

        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start">

            <!-- close button -->
            <mat-icon fxFlexAlign="end" style="cursor: pointer; padding-right: 5%;">close</mat-icon>
            <mat-divider style="border:1px solid rgba(86, 93, 121, 0.13);"></mat-divider>
            <!-- close button - end -->

            <!-- menu -->
            <a class="fs-18" routerLink="/home" mat-button><b>Home</b></a>
            <a class="fs-18" routerLink="/sobre" mat-button><b>Sobre o Polo</b></a>
            <a class="fs-18" routerLink="/products/digital" mat-button><b>Produtos Digitais</b></a>
            <a class="fs-18" routerLink="/products/presencial" mat-button><b>Produtos Presenciais</b></a>
            <a class="fs-18" routerLink="/articles/last-articles" mat-button><b>Artigos</b></a>
            <a class="fs-18" routerLink="/leadership-test" mat-button><b>Autoavaliação</b></a>
            <a class="fs-18" routerLink="/forum" mat-button><b>Fórum</b></a>

            <mat-divider style="border:1px solid rgba(86, 93, 121, 0.13);"></mat-divider>

            <!-- menu - end -->

            <!-- user account actions -->
            <a mat-button style="color: #D3D3D3" class="fs-17" *ngIf="!currentLoggedUser"
                (click)="openLoginModal()">Entrar</a>

            <a mat-button style="color: #D3D3D3" class="fs-17" *ngIf="currentLoggedUser"
                href="{{environment.AREA_LOGADA_OLD + 'admin/artigos'}}">Meu Polo</a>

            <a mat-button style="color: #D3D3D3" class="fs-17" *ngIf="currentLoggedUser" (click)="logout()">Sair</a>
            <!-- user account actions - end -->

            <div style="padding: 10px; width: 90%;" *ngIf="!currentLoggedUser" (click)="openSignupModal()">
                <button mat-raised-button
                    style="background-color: #007AC2; font-weight: bold; text-align: center; color: white; font-weight: bold; width: 100%; height: 50px;">CADASTRE-SE</button>
            </div>

        </div>

    </mat-sidenav>

    <mat-sidenav-content>
        <div class="background-color">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="margin">
                <div fxLayout="row" fxLayoutGap="25px">
                    <img style="height: 30px;" src="./assets/img/logoSebraeBranco.png">
                    <img style="height: 30px;" src="./assets/img/logoPoloBranca.png">
                </div>

                <a (click)="right.open()">
                    <mat-icon style="color: white;height: 40px;width: 40px; font-size: 40px ;">
                        menu
                    </mat-icon>
                </a>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
