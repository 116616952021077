import { Response } from './../models/response.model';
import { Paginate } from './../models/paginate.model';
import { Comment } from './../models/comment.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsService extends BaseService {

  modelName = 'comentarios';
  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  get urlApi() {
    return environment.apiEndpoint + this.modelName;
  }

  getRecentQuestions(params?: {}): Observable<Response<Paginate<Comment>>> {
    if (!params) {
      return this.http.get<Response<Paginate<Comment>>>(this.urlApi + '/' + 'duvidas-recentes');
    }
    return this.http.get<Response<Paginate<Comment>>>(this.urlApi + '/' + 'duvidas-recentes' + this.paramsToQueryString(params));
  }

  getRecentAnswers(params?: {}): Observable<Response<Paginate<Comment>>> {
    if (!params) {
      return this.http.get<Response<Paginate<Comment>>>(this.urlApi + '/' + 'respostas-recentes');
    }
    return this.http.get<Response<Paginate<Comment>>>(this.urlApi + '/' + 'respostas-recentes' + this.paramsToQueryString(params));
  }

  getPopular(params?: {}): Observable<Response<Comment[]>> {
    if (!params) {
      return this.http.get<Response<Comment[]>>(this.urlApi + '/' + 'populares');
    }
    return this.http.get<Response<Comment[]>>(this.urlApi + '/' + 'populares' + this.paramsToQueryString(params));
  }

  postComment(payload: {}): Observable<Response<Comment>>{
    return this.http.post<Response<Comment>>(this.urlApi, payload, {headers: this.createHeader});
  }

}
