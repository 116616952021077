import { BehaviorSubject } from 'rxjs';
import { SignUpModalComponent } from './../../shared/components/signup-modal/signup-modal.component';
import { LoginModalComponent } from './../../shared/components/login-modal/login-modal.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class LoginModalService {
  mobile = window.innerWidth < 960;

  openedModalSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private dialog: MatDialog,
  ) { }

  openModalLogin() {
    let dialogRef = this.dialog.open(LoginModalComponent, {
        width: this.mobile ? '100%' : '735px',
        maxWidth: this.mobile ? '100%' : 'auto',
        height: this.mobile ? '100%' : '485px',
        // maxHeight: this.mobile ? '100%' : '85vh',
        panelClass: 'no-padding-modal',
        // disableClose: true,
        autoFocus: false,
        scrollStrategy: new NoopScrollStrategy(),
        data: {
            
        }
    });
    this.openedModalSubject.next(dialogRef);

    dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.openModalSignUp) {
            this.openModalSignUp();
        }
    })
}

openModalSignUp() {
    let dialogRef = this.dialog.open(SignUpModalComponent, {
        width: this.mobile ? '100%' : 'auto',
        maxWidth: this.mobile ? '100%' : 'auto',
        height: this.mobile ? '100%' : 'auto',
        maxHeight: this.mobile ? '100%' : '85vh',
        panelClass: 'no-padding-modal',
        autoFocus: false,
        scrollStrategy: new NoopScrollStrategy()
    });
    this.openedModalSubject.next(dialogRef);
    
    dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.openModalLogin) {
            this.openModalLogin();
        }
    })
}
}
