<div style="font-size: 12px; height: 100%;">
    <div flex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="space-between center" fxLayoutGap.lt-md="20px" style="height: 100%">
        <div fxFlex.gt-sm="300px" fxFlexOrder.lt-md="3" class="logo-box" ngClass.lt-md="logo-box-mobile"
            [ngStyle]="{'height': isCep ? '740px' : '500px' }">
            <div flex fxLayout="column" fxLayoutAlign="center center">
                <img fxHide.lt-md style="margin-top:160px" src="./assets/img/logoSebraeBranco.png">
                <span class="already-registered">Já possui conta ?</span>
                <button class="btn-light-blue" mat-raised-button (click)="redirectToLogin()">Entrar</button>
            </div>
        </div>
        <div fxFlexOrder.lt-md="2" ngClass.gt-sm="px-4 mt-2" ngClass.lt-md="w-90 mt-0">
            <form [formGroup]="signupForm" (ngSubmit)="signup()">
                <div fxLayout="column" fxLayoutAlign="center start" style="padding-bottom: 20px;">
                    <span class="login-form-box  mb-1" ngClass.lt-md="login-form-box-mobile">Cadastro</span>
                    <br>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="22px">
                        <mat-form-field fxFlex="45%" style="margin-top:20px;" appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input matInput placeholder="000.000.000-00" mask="000.000.000-00" formControlName="cpf"
                                (blur)="validateCPF()">
                            <mat-error
                                *ngIf="signupForm.controls['cpf'].errors && signupForm.controls['cpf'].errors.exists">
                                CPF
                                já cadastrado</mat-error>
                            <mat-error
                                *ngIf="signupForm.controls['cpf'].errors  && signupForm.controls['cpf'].errors.cpfInvalido">
                                CPF inválido</mat-error>
                            <mat-error
                                *ngIf="signupForm.controls['cpf'].errors  && signupForm.controls['cpf'].touched
                                 && !signupForm.controls['cpf'].errors.cpfInvalido && !signupForm.controls['cpf'].errors.exists">
                                CPF deve ser preenchido</mat-error>
                        </mat-form-field>
                        <div fxFlex="40%">
                            <h4 mat-subheader style="margin-bottom: 8px;margin-top:0px">Sexo</h4>
                            <mat-radio-group aria-label="Select an option" fxLayout="row" formControlName="sexo">
                                <mat-radio-button style="font-size: 12px" value="F" [checked]="true">Fem.
                                </mat-radio-button>
                                <mat-radio-button style="font-size: 12px" value="M">Masc.</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>Nome Completo</mat-label>
                        <input matInput formControlName="nome">
                        <mat-error
                            *ngIf="signupForm.controls['nome'].errors && signupForm.controls['nome'].errors.nomeInvalido">
                            Nome deve ser composto</mat-error>
                        <mat-error
                            *ngIf="signupForm.controls['nome'].errors && signupForm.controls['nome'].touched &&  !signupForm.controls['nome'].errors.nomeInvalido">
                            Nome Completo é obrigatório</mat-error>
                    </mat-form-field>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="22px">
                        <mat-form-field fxFlex="70%" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email" (blur)="validateEmail()"
                                maxlength="50">

                            <mat-error
                                *ngIf="signupForm.controls['email'].errors && signupForm.controls['email'].errors.exists">
                                E-mail já cadastrado
                            </mat-error>

                            <mat-error
                                *ngIf="signupForm.controls['email'].errors  && signupForm.controls['email'].errors.emailInvalido">
                                E-mail deve ser preenchido</mat-error>

                            <mat-error
                                *ngIf="signupForm.controls['email'].errors  && signupForm.controls['email'].touched
                                 && !signupForm.controls['email'].errors.emailInvalido && !signupForm.controls['email'].errors.exists">
                                E-mail deve ser preenchido corretamente</mat-error>

                        </mat-form-field>
                        <mat-form-field fxFlex="30%" appearance="outline">
                            <mat-label>Data de nasc.</mat-label>
                            <input matInput placeholder="00/00/0000" mask="00/00/0000" formControlName="dataNascimento"
                                [dropSpecialCharacters]="false">
                            <mat-error
                                *ngIf="signupForm.controls['dataNascimento'].errors && signupForm.controls['dataNascimento'].errors.dataInvalida">
                                Data inválida</mat-error>
                            <mat-error
                                *ngIf="signupForm.controls['dataNascimento'].errors && signupForm.controls['dataNascimento'].touched && !signupForm.controls['dataNascimento'].errors.dataInvalida">
                                Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="22px">
                        <mat-form-field fxFlex="70%" appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput placeholder="(00) 00000-0000" mask="(00) 00009-0000"
                                formControlName="telefone">
                            <mat-error
                                *ngIf="signupForm.controls['telefone'].errors && signupForm.controls['telefone'].touched">
                                Telefone é obrigatório</mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="30%" appearance="outline">
                            <mat-label>CEP</mat-label>
                            <input matInput placeholder="00000-000" mask="00000-000" formControlName="cep"
                                (blur)="getCep()">
                            <div matSuffix *ngIf="isCepLoading">
                                <app-loading-spinner class="spinner loading-indicator"
                                    diameter="20"></app-loading-spinner>
                            </div>
                            <mat-error
                                *ngIf="signupForm.controls['cep'].errors && signupForm.controls['cep'].touched && signupForm.controls['cep'].errors.required">
                                CEP é obrigatório</mat-error>
                            <mat-error
                                *ngIf="signupForm.controls['cep'].errors && signupForm.controls['cep'].touched && signupForm.controls['cep'].errors.notFound">
                                CEP não encontrado</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="22px">
                        <mat-form-field fxFlex="60%" id="cepRestrict01" style="display:none" appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput formControlName="cidade">
                            <mat-error
                                *ngIf="signupForm.controls['cidade'].errors && signupForm.controls['cidade'].touched">
                                Cidade é obrigatório</mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="40%" id="cepRestrict02" style="display:none" appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput formControlName="estado">
                            <mat-error
                                *ngIf="signupForm.controls['estado'].errors && signupForm.controls['estado'].touched">
                                Estado é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="22px">
                        <mat-form-field fxFlex="100%" id="cepRestrict03" style="display:none" appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input matInput formControlName="logradouro">
                            <mat-error
                                *ngIf="signupForm.controls['logradouro'].errors && signupForm.controls['logradouro'].touched">
                                Logradouro é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="30px">
                        <mat-form-field fxFlex="50%" id="cepRestrict04" style="display:none" appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput formControlName="bairro">
                            <mat-error
                                *ngIf="signupForm.controls['bairro'].errors && signupForm.controls['bairro'].touched">
                                Bairro é obrigatório</mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50%" id="cepRestrict05" style="display:none" appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput formControlName="numero">
                            <mat-error
                                *ngIf="signupForm.controls['numero'].errors && signupForm.controls['numero'].touched">
                                Número é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="22px">
                        <mat-form-field id="cepRestrict06" style="display:none" fxFlex="100%" appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput formControlName="complemento">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start" fxLayoutGap="22px">
                        <mat-form-field fxFlex="50%" appearance="outline">
                            <mat-label>Senha</mat-label>
                            <input matInput type="password" formControlName="senha">
                            <mat-error
                                *ngIf="signupForm.controls['senha'].errors && signupForm.controls['senha'].touched ">
                                Senha deve conter 6 digitos</mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50%" appearance="outline">
                            <mat-label>Confirma sua senha</mat-label>
                            <input matInput type="password" formControlName="confSenha">
                            <mat-error
                                *ngIf="signupForm.controls['confSenha'].errors && signupForm.controls['confSenha'].errors.senhaInvalida && signupForm.controls['confSenha'].touched && signupForm.controls['confSenha'].value">
                                Senhas não coincidem</mat-error>
                            <mat-error
                                *ngIf="signupForm.controls['confSenha'].errors && signupForm.controls['confSenha'].touched ">
                                Confirme sua senha</mat-error>
                        </mat-form-field>
                    </div>

                    <mat-error style="color:red" class="my-1" *ngIf="error" [innerHTML]="error"></mat-error>
                    <div fxLayout="row" fxLayoutGap="45px" fxFlexFill fxLayoutAlign="end center">
                        <button style="padding:7px 45px; background-color: #007AC2; color:white"
                            [disabled]="isDisabled()" [ngClass]="{'isDisabled': isDisabled()}" mat-raised-button>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                                <span> Cadastrar </span>
                                <app-loading-spinner *ngIf="isDisabled()" class="spinner loading-indicator"
                                    diameter="20"></app-loading-spinner>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <mat-icon fxFlexOrder.lt-md="1" fxFlexAlign.lt-md="end" style="cursor: pointer" (click)="closeModal()">close
        </mat-icon>
    </div>

</div>