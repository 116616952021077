import { ProfileComponent } from './views/forum/profile/profile.component';
import { AuthGuard } from './guards/auth.guard';
import { AnswerBlockComponent } from './shared/components/forum/answer-block/answer-block.component';
import { QuestionListComponent } from './views/forum/question-list/question-list.component';
import { QuestionDetailsComponent } from './views/forum/question-details/question-details.component';
import { ForumComponent } from './views/forum/forum.component';
import { CourseEventsComponent } from './views/course-events/course-events.component';
import { PresencialDetailsComponent } from './views/products/presencial/presencial-details/presencial-details.component';
import { DigitalDetailsComponent } from './views/products/digital/digital-details/digital-details.component';
import { ReadArticleComponent } from './views/articles/read-article/read-article.component';
import { LastArticlesComponent } from './views/articles/last-articles/last-articles.component';

import { LeadershipTestComponent } from './views/leadership-test/leadership-test.component';
import { PillarComponent } from './views/pillar/pillar.component';

import { PresencialComponent } from './views/products/presencial/presencial.component';
import { DigitalComponent } from './views/products/digital/digital.component';
import { AboutComponent } from './views/about/about.component';
import { HomeComponent } from './views/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeadershipHomeComponent } from './views/leadership-home/leadership-home.component';
import { DoTheTestComponent } from './views/do-the-test/do-the-test.component';
import { CourseEventsDetailsComponent } from './views/course-events/course-events-details/course-events-details.component';
import { LeadershipResultComponent } from './views/leadership-result/leadership-result.component';
import { YourHistoricTestComponent } from './views/your-historic-test/your-historic-test.component';
import { QueroMaisInformacoesComponent } from './views/quero-mais-informacoes/quero-mais-informacoes.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'home'
  // },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'sobre',
    loadChildren: () => import('./views/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'enviar-email/:id',
    loadChildren: () => import('./views/quero-mais-informacoes/quero-mais-informacoes.module').then(m => m.QueroMaisInformacoesModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'articles',
    loadChildren: () => import('./views/articles/article.module').then(m => m.ArticleModule)
  },
  {
    path: 'course-events',
    loadChildren: () => import('./views/course-events/course-events.module').then(m => m.CourseEventsModule)
  },
  {
    path: 'forum',
    loadChildren: () => import('./views/forum/forum.module').then(m => m.ForumModule)
  },
  {
    path: 'pillar/:id',
    loadChildren: () => import('./views/pillar/pillar.module').then(m => m.PillarModule)
  },
  {
    path: 'leadership-test',
    loadChildren: () => import('./views/leadership-test/leadership-test.module').then(m => m.LeadershipTestModule)
  },
  {
    path: 'leadership-home',
    loadChildren: () => import('./views/leadership-home/leadership-home.module').then(m => m.LeadershipHomeModule)
  },
  {
    path: 'do-the-test/:id',
    loadChildren: () => import('./views/do-the-test/do-the-test.module').then(m => m.DoTheTestModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'your-historic-test',
    loadChildren: () => import('./views/your-historic-test/your-historic-test.module').then(m => m.YourHistoricTestModule),
  },
  {
    path: 'leadership-result/:id',
    loadChildren: () => import('./views/leadership-result/leadership-result.module').then(m => m.LeadershipResultModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'home',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
