import { ERedeSocial } from './../../../enums/rede-social.enum';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  get ERedeSocial(){
    return ERedeSocial;
  }

  ngOnInit(): void {
  }

  redirectRedeSocial(tipo: ERedeSocial) {
    let url = '';
    switch (tipo) {
      case ERedeSocial.FACEBOOK:
        url = "https://www.facebook.com/sebrae";
        break;
      case ERedeSocial.INSTAGRAM:
        url = "https://www.instagram.com/sebrae/";
        break;
      case ERedeSocial.TWITTER:
        url = "https://twitter.com/sebrae";
        break;
      case ERedeSocial.LINKEDIN:
        url = "https://www.linkedin.com/company/sebrae/";
        break;
      case ERedeSocial.YOUTUBE:
          url = "https://www.youtube.com/user/tvsebrae";
          break;
    }
    window.open(url, '_blank');
  }
}
