import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Injectable()
export class FormValidator {

    cpfValidator(formControlCpf: AbstractControl) {
        const cpf = formControlCpf.value;
        if (cpf) {
            let numbers, digits, sum, i, result, equalDigits;
            equalDigits = 1;

            for (i = 0; i < cpf.length - 1; i++) {
                if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
                    equalDigits = 0;
                    break;
                }
            }
            if (!equalDigits) {
                numbers = cpf.substring(0, 9);
                digits = cpf.substring(9);
                sum = 0;
                for (i = 10; i > 1; i--) {
                    sum += numbers.charAt(10 - i) * i;
                }
                result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

                if (result !== Number(digits.charAt(0))) {
                    return { cpfInvalido: true };
                }
                numbers = cpf.substring(0, 10);
                sum = 0;

                for (i = 11; i > 1; i--) {
                    sum += numbers.charAt(11 - i) * i;
                }
                result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

                if (result !== Number(digits.charAt(1))) {
                    return { cpfInvalido: true };
                }
                return null;
            } else {
                return { cpfInvalido: true };
            }
        }
        else {
            return null;
        }
    }

    dateValidator(formControlDate: AbstractControl) {
        if (formControlDate.value) {
            const data = moment(formControlDate.value, "DD/MM/YYYY");
            const dataAtual = (moment(new Date(), "DD/MM/YYYY"));
            if (data.isValid()) {
                if (data < dataAtual) {
                    return null;
                } else {
                    return { dataInvalida: true };
                }
            } else {
                return { dataInvalida: true };
            }
        }
    }

    nameValidator(formControlName: AbstractControl) {
        if (formControlName.value) {
            const verify = formControlName.value.trim().split(" ");
            if (verify.length < 2) {
                return { nomeInvalido: true };
            } else {
                return null;
            }
        }
    }
    confirmPassword(form: AbstractControl) {
        if (form) {
            if (form.get('senha') && form.get('senha').value && form.get('confSenha') && form.get('confSenha').value && form.get('senha').value == form.get('confSenha').value) {
                form.get('confSenha').setErrors(null);
            } else {
                form.get('confSenha').setErrors({ senhaInvalida: true });
            }
        }
        return null;
    }
}