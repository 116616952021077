import { environment } from './../../../../environments/environment';
import { UtilService } from './../../../services/util.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Popup } from './../../../models/popup.model';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-popup-success-modal',
  templateUrl: './popup-success-modal.component.html',
  styleUrls: ['./popup-success-modal.component.scss']
})
export class PopupSuccessModalComponent implements OnInit {
  storageUrl = environment.BASE_STORAGE_URL;

  fileName: string = '';
  link: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Popup,
    private utilService: UtilService,
    private dialogRef: MatDialogRef<PopupSuccessModalComponent>
  ) { }

  ngOnInit(): void {
    this.fileName = this.utilService.getFileName(this.data.pathAnexo);
    this.link = this.data.link;
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  download(): void {
    window.open(this.storageUrl + this.data.pathAnexo, '_blank');
  }

  redirecionar(): void {
    window.open(this.link, '_blank')
  }
}
