import { AuthBaseService } from './auth/auth-base.service';
import { environment } from './../../environments/environment';
import { BaseService } from './base/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  private modelName = 'usuarios';
  constructor(
    protected http: HttpClient,
    private baseService: BaseService
  ) {
    super(http);
  }

  get urlApi() {
    return environment.apiEndpoint + this.modelName;
  }

  getUserData() {
    return this.http.get(`${environment.apiEndpoint}/${this.modelName}` + '/meus-dados', { headers: this.baseService.createHeader });
  }

}