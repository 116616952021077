import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  triggerRefreshCommentsSubject: BehaviorSubject<{ submit: boolean }> = new BehaviorSubject<{ submit: boolean }>({ submit: false });

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  isEmptyObject(obj: {}): boolean {
    if (obj === null || Object.keys(obj).length === 0) {
      return true;
    }
    return false;
  }

  sanitizeUrl(product: string): SafeResourceUrl {
    if (product) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(product as string);
    }
    return null;
  }

  objToString(obj: {}[], key: string): string {
    let string = '';
    for (let i = 0; i < obj.length; i++) {
      string += obj[i][key];

      if (i + 1 < obj.length) {
        string = string + ', ';
      }
    }
    return string;
  }

  arrayToString(arr: string[]): string {
    let string = '';
    for (let i = 0; i < arr.length; i++) {
      string += arr[i];

      if (i + 1 < arr.length) {
        string = string + ', ';
      }
    }

    return string;
  }

  getFileName(str: string): string {
    return /[^/]*$/.exec(str)[0];
}
}
