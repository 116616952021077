import { BaseModule } from './shared/modules/base/base.module';
import { UsersService } from './services/users.service';
import { SendMailService } from './services/send-mail.service';
import { PopupService } from './services/popup.service';
import { RecomendedSolutionService } from './services/recomended-solution.service';
import { BookService } from './services/books.service';
import { AlertService } from './services/alert.service';
import { ResultModalComponent } from './shared/components/result-modal/result-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormValidator } from './shared/validators/form.validator';
import { LoginModalService } from './services/auth/login-modal.service';
import { AuthBaseService } from './services/auth/auth-base.service';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './layout/main-static/header/header.component';
import { FooterComponent } from './layout/main-static/footer/footer.component';
import { LoginModalComponent } from './shared/components/login-modal/login-modal.component';
import { SignUpModalComponent } from './shared/components/signup-modal/signup-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CommentsService } from './services/comments.service';
import { ForumsService } from './services/foums.service';
import { SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { PopupModalComponent } from './shared/components/popup-modal/popup-modal.component';
import { PopupSuccessModalComponent } from './shared/components/popup-success-modal/popup-success-modal.component';
import { ConfirmacaoDeContatoModalComponent } from './shared/components/product-info-blue-stripe/confirmacao-de-contato-modal/confirmacao-de-contato-modal.component';
import { BrowserModule } from '@angular/platform-browser';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginModalComponent,
    SignUpModalComponent,
    ResultModalComponent,
    PopupModalComponent,
    PopupSuccessModalComponent,
    ConfirmacaoDeContatoModalComponent,
  ],
  imports: [
    BaseModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    UsersService,
    AuthBaseService,
    AlertService,
    LoginModalService,
    FormValidator,
    CommentsService,
    ForumsService,
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpCustomInterceptor,
    //   multi: true
    // },
    { provide: MatDialogRef, useValue: {} },
    BookService,
    RecomendedSolutionService,
    PopupService,
    SendMailService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginModalComponent,
    SignUpModalComponent,
    ResultModalComponent,
    PopupModalComponent,
    PopupSuccessModalComponent,
    ConfirmacaoDeContatoModalComponent
  ],
})
export class AppModule { }
