import { Response } from './../models/response.model';
import { AuthBaseService } from './auth/auth-base.service';
import { Cep } from './../models/cep.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CepService {


  constructor(
    protected http: HttpClient,
    protected authBaseService: AuthBaseService
  ) {
  }


  getCep(cep: string): Observable<Response<Cep>> {
    return this.http.get<Response<Cep>>(environment.apiEndpoint + '/consultar-cep/' + cep);
  }

}
