import { BaseService } from 'src/app/services/base/base.service';
import { Router } from '@angular/router';
import { Response } from './../../models/response.model';
import { UsuarioCadastro } from './../../models/user/user-register-base.model';
import { environment } from './../../../environments/environment';
import { Login } from './../../models/login.model';
import { Token } from './../../models/token.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthBaseService {
  usuarioLogadoSubject: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);

  constructor(
    protected http: HttpClient,
    private router: Router,
    private baseService: BaseService
  ) {
    if (this.getToken() !== null) {

      setTimeout(() => {
        this.getPerfilLogado().subscribe((res: Response<Token>) => {
          this.usuarioLogadoSubject.next(res.data);
        })
      }, 2000);
      this.baseService.setAuthorization(this.getToken());
    }
  }

  getToken(): string {
    return window.localStorage.getItem('token');
  }

  watchUsuarioLogado(): Observable<Token> {
    return this.usuarioLogadoSubject;
  }

  getPerfilLogado(returnPromise: boolean = false): Observable<Response<Token>> | any {
    let url = environment.apiEndpoint + '/' + 'login';
    let http = this.http.get<Response<Token>>(url, { headers: this.baseService.createHeader })
    if (returnPromise) {
      return http.toPromise();
    }

    return http;
  }

  forgotPassword(cpf: string): Observable<string> {
    cpf = cpf.replace(/[^\d]+/g, '');
    const url = environment.apiEndpoint + '/' + 'login/recuperar-senha?login=' + cpf;

    return this.http.post<string>(url, null);
  }

  signup(usuario: UsuarioCadastro) {
    let url = environment.apiEndpoint + '/' + 'login/cadastrar';
    return this.http.post(url, usuario, { headers: this.baseService.createHeaderNoAuth });
  }

  signupNoAddress(usuario: UsuarioCadastro) {
    let url = environment.apiEndpoint + '/' + 'login/cadastrar-sem-endereco';
    return this.http.post(url, usuario, { headers: this.baseService.createHeaderNoAuth });
  }
  
  setToken(token: Token): void {
    window.localStorage.setItem('token', token.id);
    this.baseService.authorization = token.id;
    this.usuarioLogadoSubject.next(token);
  }

  validateCPF(cpf: string): Observable<Response<string>> {
    let url = environment.apiEndpoint + '/' + 'login/cadastrar?cpf=' + cpf;
    return this.http.get<Response<string>>(url, { headers: this.baseService.createHeaderNoAuth });
  }

  validateEmail(email: string): Observable<Response<boolean>> {
    let url = environment.apiEndpoint + '/' + 'login/cadastrar?email=' + email;
    return this.http.get<Response<boolean>>(url, { headers: this.baseService.createHeaderNoAuth });
  }

  login(auth: Login): Observable<Response<Token>> {
    const url = environment.apiEndpoint + '/' + 'login';
    return this.http.post<Response<Token>>(url, { login: auth.login, senha: auth.password });
  }

  logout(): void {
    window.localStorage.removeItem('token');
    this.usuarioLogadoSubject.next(null);
    this.baseService.setAuthorization("");
    this.router.navigate(['/home']);
  }
}
