<div *ngIf="!href">
    <div class="{{btnClass}}" *ngIf="!txtRight">
        <a mat-flat-button style="width: 100%;" class="other" type="button" fxLayoutAlign="center center" [disableRipple]="disableRipple">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <span style="font-family: {{fontFamily}}; font-size: {{fontSize}};" class="btn-size">{{txt}}</span>
            </div>
        </a>
    </div>

    <div class="{{btnClass}}" *ngIf="txtRight">
        <a mat-flat-button style="width: 100%;" class="other" type="button" [disableRipple]="disableRipple">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <span style="font-family: {{fontFamily}}; font-size: {{fontSize}};" class="btn-size">{{txt}}</span>

                <span style="font-family: {{fontFamily}}; font-size: {{fontSize}};" class="btn-size">{{txtRight}}</span>
            </div>
        </a>
    </div>
</div>



<div *ngIf="href">
    <div class="{{btnClass}}" *ngIf="!txtRight">
        <a mat-flat-button style="width: 100%;" class="other" type="button" fxLayoutAlign="center center" [href]="href">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <span style="font-family: {{fontFamily}}; font-size: {{fontSize}};" class="btn-size">{{txt}}</span>
            </div>
        </a>
    </div>

    <div class="{{btnClass}}" *ngIf="txtRight">
        <a mat-flat-button style="width: 100%;" class="other" type="button" [href]="href">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <span style="font-family: {{fontFamily}}; font-size: {{fontSize}};" class="btn-size">{{txt}}</span>

                <span style="font-family: {{fontFamily}}; font-size: {{fontSize}};" class="btn-size">{{txtRight}}</span>
            </div>
        </a>
    </div>
</div>