<div style="overflow: hidden;font-size: 12px; height: 100%;">

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="space-between center" fxLayoutGap.lt-md="10px" style="height: 100%; ">

        <div fxFlex.gt-sm="300px" fxFlexOrder.lt-md="3" class="logo-box" ngClass.lt-md="logo-box-mobile">
            <div fxLayout="column" fxLayoutAlign="center center">
                <img fxHide.lt-md style="margin-top:90px" src="./assets/img/logoSebraeBranco.png">
                <span class="already-registered" *ngIf="isForgotPassword">Já possui conta ?</span>
                <button class="btn-light-blue" mat-raised-button (click)="toggleForgotPassword(false)"
                    *ngIf="isForgotPassword">Entrar</button>
                <span class="not-registered" *ngIf="!isForgotPassword">Não possui cadastro ainda?</span>
                <span class="sigin-now-free" *ngIf="!isForgotPassword">Cadastre-se e ganhe acesso a todos os nosso
                    serviços gratuitos</span>
                <button type="button" class="btn-light-blue" mat-raised-button (click)="redirectToSignUp()"
                    *ngIf="!isForgotPassword">Cadastrar</button>
            </div>
        </div>

        <div fxFlexOrder.lt-md="2" [ngClass.gt-sm]="isForgotPassword ? 'px-4-alt mt-2' : 'px-4 mt-2'" ngClass.lt-md="w-90 mt-0">
            <form [formGroup]="loginForm" (ngSubmit)="submit()" *ngIf="!isForgotPassword">
                <div fxLayout="column" fxLayoutAlign="center start">
                    <span class="login-form-box" ngClass.lt-md="login-form-box-mobile"
                        fxFlexAlign.lt-md="start">Entrar</span>
                    <mat-form-field style="margin-top:20px; width: 100%;" appearance="outline">
                        <mat-label>CPF</mat-label>
                        <input matInput mask="000.000.000-00" formControlName="login" type="text">
                    </mat-form-field>
                    <mat-form-field style="width: 100%;" appearance="outline">
                        <mat-label>Senha</mat-label>
                        <input matInput formControlName="password" type="password">
                    </mat-form-field>

                    <mat-error class="mb-1" *ngIf="error">{{error}}</mat-error>
                    <mat-hint style="color:green" class="mb-1" *ngIf="success">Login OK!</mat-hint>


                    <div fxLayout="row" fxLayoutGap.gt-sm="45px" style="width: 100%"
                        fxLayoutAlign.lt-md="space-between center">
                        <button type="button" mat-button (click)="toggleForgotPassword(true)"
                            style="color:rgba(32, 35, 46, 0.55); text-decoration: underline">esqueceu a
                            senha?</button>
                        <button style="padding:0px 45px; background-color: #007AC2; color:white"
                            mat-raised-button>Entrar</button>
                    </div>
                </div>

            </form>

            <form [formGroup]="forgotForm" (ngSubmit)="submit()" *ngIf="isForgotPassword">
                <div fxLayout="column" fxFlex="100%" fxLayoutAlign="center start">
                    <span class="login-form-box" ngClass.lt-md="login-form-box-mobile" fxFlexAlign.lt-md="start">Esqueci
                        a senha</span>
                    <mat-form-field style="margin-top:20px; width: 100%;" appearance="outline">
                        <mat-label>CPF</mat-label>
                        <input matInput mask="000.000.000-00" formControlName="login" type="text" autocomplete="off"
                            (click)="submit()">


                    </mat-form-field>
                    <mat-hint style="color:red" class="mb-1" *ngIf="error">{{error}}
                    </mat-hint>

                    <div fxLayout="row" fxLayoutGap.gt-sm="185px" style="width: 100%"
                        fxLayoutAlign.lt-md="space-between center">
                        <div fxLayout="column" fxLayoutAlign.gt-md="end center" fxLayoutGap.gt-sm="45px"
                            style="width: 100%" fxLayoutAlign.lt-md="space-between center">
                        </div>

                        <div fxLayout="column" fxLayoutAlign.gt-md="end center" fxLayoutGap.gt-sm="45px"
                            style="width: 100%" fxLayoutAlign.lt-md="space-between center">

                            <button style="padding:0px 45px; background-color: #007AC2; color:white"
                                mat-raised-button>Enviar</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>

        <mat-icon fxFlexOrder.lt-md="1" fxFlexAlign.lt-md="end" style="cursor: pointer" (click)="closeModal()">close
        </mat-icon>

    </div>

</div>