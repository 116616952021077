import { environment } from 'src/environments/environment';
import { Token } from './../../../models/token.model';
import { AuthBaseService } from './../../../services/auth/auth-base.service';
import { LoginModalService } from './../../../services/auth/login-modal.service';
import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentLoggedUser: Token = new Token();
  environment = environment;
  opAnterior: string = '';

  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  constructor(
    private loginModalService: LoginModalService,
    private authBaseService: AuthBaseService,
    private router: Router,
  ) {
    this.watchUrl()
  }

  watchUrl(): void {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {

        let aTags = document.getElementsByClassName('optMenu');
        for (let i = 0; i < aTags.length; i++) {
          aTags[i].classList.remove('menu-selected');
        }

        this.addClass(res.urlAfterRedirects);
      }
    })
  }

  addClass(str: string): void {
    switch (true) {
      case str.includes('sobre'):
        document.getElementById('sobre').classList.add('menu-selected');
        break;
      case str.includes('course-events'):
        document.getElementById('cursosEventos').classList.add('menu-selected');
        break;
      case str.includes('last-articles') || str.includes('read-article'):
        document.getElementById('lastArticles').classList.add('menu-selected');
        break;
      case str.includes('leadership-test') || str.includes('leadership-home') || str.includes('do-the-test') || str.includes('your-historic-test') || str.includes('leadership-result'):
        document.getElementById('leadershipTest').classList.add('menu-selected');
        break;
      case str.includes('home'):
        document.getElementById('home').classList.add('menu-selected');
        break;
      case str.includes('digital') || str.includes('presencial'):
        document.getElementById('produto').classList.add('menu-selected');
        break;
    }
  }

  ngOnInit(): void {
    this.getLoggedUser();
  }

  getLoggedUser(): void {
    this.authBaseService.watchUsuarioLogado().subscribe((res: Token) => {
      this.currentLoggedUser = res;
    })
  }

  openLoginModal(): void {
    this.loginModalService.openModalLogin();
  }

  openSignupModal(): void {
    this.loginModalService.openModalSignUp();
  }

  logout(): void {
    this.authBaseService.logout();
  }

  goToLink(url: string): void {
    window.open(url, "_blank");
  }
}
