<div>
  <div  [ngClass.lt-md]="'margin-height'" style="position: fixed; width: 100%; z-index: 2;">
    <app-header></app-header>
  </div>
  <div class="space-from-header min-height">
      <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>

