<div class="footer" id="footer">
    <div class="footer-upper" fxLayout="column" fxLayoutAlign="center center">
        <div class="margin" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center"
            fxLayoutGap.lt-md="25px">
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign.lt-md="space-between center">
                <a href="https://www.sebrae.com.br/" target="_blank"><img class="pointer" src="./assets/img/logoSebraeBranco.png"></a>
                <img class="pointer" src="./assets/img/logoPoloBranca.png" routerLink="/home">
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="48px" fxLayoutGap.lt-md="25px">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <span class="txt-small">ENTRE EM CONTATO</span>
                    <span class="txt-big">0800 570 0800</span>
                    <span class="txt-small">De segunda a sexta, das 8h às 20h.</span>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center">
                    <span class="txt-big">SEBRAE</span>
                    <span class="txt-small"><a style="text-decoration: none; color: #ffffff;" target="_blank" href="https://www.sebrae.com.br">www.sebrae.com.br</a></span>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom" fxLayoutAlign="center center">
        <div class="margin">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap.lt-md="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
                    <fa-icon class="pointer" (click)="redirectRedeSocial(ERedeSocial.FACEBOOK)" [icon]="['fab', 'facebook-f']" [classes]="['size-15x']"></fa-icon>
                    <fa-icon class="pointer" (click)="redirectRedeSocial(ERedeSocial.INSTAGRAM)" [icon]="['fab', 'instagram']" [classes]="['size-15x']"></fa-icon>
                    <fa-icon class="pointer" (click)="redirectRedeSocial(ERedeSocial.LINKEDIN)" [icon]="['fab', 'linkedin']" [classes]="['size-15x']"></fa-icon>
                    <fa-icon class="pointer" (click)="redirectRedeSocial(ERedeSocial.TWITTER)" [icon]="['fab', 'twitter']" [classes]="['size-15x']"></fa-icon>
                    <fa-icon class="pointer" (click)="redirectRedeSocial(ERedeSocial.YOUTUBE)" [icon]="['fab', 'youtube']" [classes]="['size-15x']"></fa-icon>
                </div>
                <span class="txt-small">
                    @ Copyright - Sebrae - Polo de Liderança
                </span>
            </div>
        </div>
    </div>
</div>
