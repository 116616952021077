import { environment } from './../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  authorization: string = "";
  constructor(
    protected http: HttpClient
  ) { }

  get urlApi() {
    return environment.apiEndpoint;
  }

  setAuthorization(auth: string): void {
    this.authorization = auth;
  }

  get createHeader(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.append('APP_KEY', 'ff14205f-5a71-4c1a-9893-811b6158f5f9');
    headers = headers.append('Authorization', this.authorization);
    return headers;
  }

  get createHeaderNoAuth(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.append('APP_KEY', 'ff14205f-5a71-4c1a-9893-811b6158f5f9');

    return headers;
  }

  getAllNoHeader(modelName: string, params?: {}, paramString?: string) {
    let http = this.http.get(this.urlApi + '/' + modelName);

    if (params) {
      http = this.http.get(this.urlApi + '/' + modelName + this.paramsToQueryString(params));

      if (paramString) {
        http = this.http.get(this.urlApi + '/' + modelName + (this.paramsToQueryString(params).length > 0 ? this.paramsToQueryString(params) : '?') + paramString);
      }
    }
    return http;
  }

  getAll(modelName: string, params?: {}) {
    if (!params) {
      return this.http.get(this.urlApi + '/' + modelName, { headers: this.createHeader });
    }
    return this.http.get(this.urlApi + '/' + modelName + this.paramsToQueryString(params), { headers: this.createHeader });
  }

  getByIdNoHeader(modelName: string, id: number) {
    return this.http.get(this.urlApi + '/' + modelName + '/' + id);
  }

  getById(modelName: string, id: number) {
    return this.http.get(this.urlApi + '/' + modelName + '/' + id, { headers: this.createHeader });
  }

  create(modelName: string, payload: {}) {
    return this.http.post(this.urlApi + '/' + modelName, payload, { headers: this.createHeader });
  }

  paramsToQueryString(params: any) {
    let result = '?';

    for (let propertyName in params) {
      if (params && params[propertyName] !== undefined && params[propertyName] !== null && params[propertyName] !== '') {
        if (Array.isArray(params[propertyName])) {
          for (const item of params[propertyName]) {
            result += propertyName + '=' + item + '&';
          }
        } else {
          result += propertyName + '=' + params[propertyName] + '&';
        }
      }
    }
    return result.substring(0, result.length - 1);
  }

  paramsToQueryStringArray(params: any[]) {
    let string = '';

    for (let i = 0; i < params.length; i++) {
      for (let key in params[i]) {
        string += '&' + key + '=' + params[i][key];
      }
    }
    return string;
  }
}
