import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class SendMailService extends BaseService {
  modelName = 'enviar-email';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }
}