<div fxLayout="column">
    <div>
        <span (click)="dismiss()" class="material-icons pointer" fxLayoutAlign="end" style="margin: 1%; color: red;">
            close
        </span>
    </div>

    <div fxLayout="column" fxLayoutGap="42px">
        <div class="popup-title" fxLayout="column" fxLayoutGap="17px" fxLayoutAlign="center center">
            <span class="title">{{data.nome}}</span>
            <span class="subtitle">{{data.mensagem}}</span>
        </div>

        <form [formGroup]="form" class="blue-stripe" *ngIf="haveInputs">
            <div fxLayout="column" class="blue-stripe-margin popup-input-style">
                <div fxLayout="row wrap" fxLayoutGap="20px grid">
                    <mat-form-field fxFlex="50%" appearance="none" *ngIf="showInput('Nome')">
                        <mat-label>Nome completo</mat-label>
                        <input matInput formControlName="nome">
                        <mat-error *ngIf="form.get('nome')?.hasError('required')">
                            Este campo é requerido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50%" appearance="none" *ngIf="showInput('E-mail')">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email">
                        <mat-error *ngIf="form.get('email')?.hasError('required')">
                            Este campo é requerido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50%" appearance="none" *ngIf="showInput('Telefone')">
                        <mat-label>Telefone</mat-label>
                        <input matInput formControlName="telefone" placeholder="(00) 00000-0000" mask="(00) 00000-0000">
                        <mat-error *ngIf="form.get('telefone')?.hasError('required')">
                            Este campo é requerido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50%" appearance="none" *ngIf="showInput('Empresa')">
                        <mat-label>Empresa</mat-label>
                        <input matInput formControlName="empresa">
                        <mat-error *ngIf="form.get('empresa')?.hasError('required')">
                            Este campo é requerido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50%" appearance="none" *ngIf="showInput('Cidade')">
                        <mat-label>Cidade</mat-label>
                        <input matInput formControlName="municipio">
                        <mat-error *ngIf="form.get('cidade')?.hasError('required')">
                            Este campo é requerido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50%" appearance="none" *ngIf="showInput('Estado')">
                        <mat-label>UF</mat-label>
                        <input matInput formControlName="cidade" mask="SS">
                        <mat-error *ngIf="form.get('cidade')?.hasError('required')">
                            Este campo é requerido
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
        </form>

        <div fxLayout="row" fxLayoutAlign="center center">
            <app-button fxFlex="30" [txt]="data.nomeBotaoCustomizado" (click)="submit()"
                [btnClass]="'btn-blue-filled-no-animation popup-padding popup-style'" [fontFamily]="'Montserrat'">
            </app-button>
        </div>

        <div></div>
    </div>
</div>