import { Forum } from './../models/forum.model';
import { Paginate } from '../models/paginate.model';
import { Response } from '../models/response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ForumsService extends BaseService {

  modelName = 'forum';
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  get urlApi() {
    return environment.apiEndpoint + this.modelName;
  }

  getForum(params?: {}): Observable<Response<Paginate<Forum>>> {
    if (!params) {
      return this.http.get<Response<Paginate<Forum>>>(this.urlApi);
    }
    return this.http.get<Response<Paginate<Forum>>>(this.urlApi + this.paramsToQueryString(params));
  }

}
