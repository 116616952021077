<div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <div>
            <mat-icon *ngIf="result.status == 1"  class="icon success">check_circle_outline</mat-icon>
            <mat-icon *ngIf="result.status != 1" class="icon error">close</mat-icon>
        </div>
        <div>
            <p [innerHtml]="result.message.data" style="text-align:center"></p>
        </div>
        <div>
            <button mat-flat-button class="btn-close" (click)="closeModal()">Fechar</button>
        </div>
    </div>

</div>