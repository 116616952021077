import { Component, OnInit, Input, Inject, HostListener } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { EResultStatus } from 'src/app/enums/result-status.enum';

@Component({
    selector: 'app-result-modal',
    templateUrl: './result-modal.component.html',
    styleUrls: ['./result-modal.component.scss']
})
export class ResultModalComponent implements OnInit {
    result: any;
    EResultStatus = EResultStatus;
    mobile = window.innerWidth < 960;

    constructor(public dialogRef: MatDialogRef<ResultModalComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.result = data;
    }

    ngOnInit(): void { }


    closeModal() {
        this.dialogRef.close();

        // if (this.result.status === EResultStatus.SUCCESS) {
        //     this.openLoginModal();
        // }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.mobile = event.target.innerWidth < 960;
    }

    // openLoginModal() {
    //     let dialogRef = this.dialog.open(LoginModalComponent, {
    //         width: this.mobile ? '100%' : 'auto',
    //         maxWidth: this.mobile ? '100%' : 'auto',
    //         height: this.mobile ? '100%' : 'auto',
    //         maxHeight: this.mobile ? '100%' : '85vh',
    //         panelClass: 'no-padding-modal',
    //         autoFocus: false
    //     });
    // }
}
