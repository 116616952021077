import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SendMailService } from './../../../services/send-mail.service';
import { Popup } from './../../../models/popup.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements OnInit {

  haveInputs: boolean = true;

  form: FormGroup = new FormGroup({
    nome: new FormControl(),
    email: new FormControl(),
    telefone: new FormControl(),
    estado: new FormControl(),
    municipio: new FormControl(),
    empresa: new FormControl(),
    cidade: new FormControl(),
  })

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PopupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Popup,
    private sendMailService: SendMailService
  ) { }

  ngOnInit(): void {
    this.cdr.detectChanges()
  }

  showInput(inputName: string): boolean {
    if (this.data.popupCampos && this.data.popupCampos.length > 0) {
      for (let i = 0; i < this.data.popupCampos.length; i++) {
        if (this.data.popupCampos[i].campoFormulario.nome === inputName) {
          this.setValidation(inputName);
          return true;
        }
      }
      return false;

    } else {
      this.haveInputs = false;
    }
  }

  setValidation(inputName: string): void {
    switch (true) {
      case inputName === 'Nome':
        this.form.get('nome').setValidators(Validators.required);
        break;
      case inputName === 'E-mail':
        this.form.get('email').setValidators(Validators.required);
        break;
      case inputName === 'Telefone':
        this.form.get('telefone').setValidators(Validators.required);
        break;
      case inputName === 'Empresa':
        this.form.get('empresa').setValidators(Validators.required);
        break;
      case inputName === 'Cidade':
        this.form.get('cidade').setValidators(Validators.required);
        break;
      case inputName === 'Estado':
        this.form.get('estado').setValidators(Validators.required);
        break;
    }

    this.form.updateValueAndValidity();
  }

  dismiss(data?: Popup): void {
    this.dialogRef.close({ data: data ? data : null });
  }

  submit(): void {
    if (this.form.valid) {
      this.sendMailService.create('enviar-email/enviar-email-popup', this.form.value).subscribe((res) => {
        if (res) {
          this.dismiss(this.data);
        }
      })
    } else {
      this.form.markAllAsTouched();
    }
  }
}
