import { ProdutosDTO, PublicacoesDTO, RecomendedSolution } from './../models/recomended-solution.model';
import { Response } from '../models/response.model';
import { AuthBaseService } from './auth/auth-base.service';
import { Cep } from '../models/cep.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class RecomendedSolutionService {


  constructor(
    protected http: HttpClient,
    protected authBaseService: AuthBaseService
  ) {
  }

  getRecomended(id: number): Observable<Response<RecomendedSolution[]>> {
    return this.http.get<Response<RecomendedSolution[]>>(environment.apiEndpoint + '/solucao-recomendada/by-tags-diagnostico/' + id);
  }

  getRecomendedPublicacoes(id: number): Observable<Response<PublicacoesDTO[]>> {
    return this.http.get<Response<PublicacoesDTO[]>>(environment.apiEndpoint + '/solucao-recomendada/by-tags-diagnostico-publicacoes-dto/' + id);
  }

  getRecomendedProdutos(id: number): Observable<Response<ProdutosDTO[]>> {
    return this.http.get<Response<ProdutosDTO[]>>(environment.apiEndpoint + '/solucao-recomendada/by-tags-diagnostico-produtos-dto/' + id);
  }

}
