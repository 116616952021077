export class Token {
    id: string;
    nome: string;
    perfil: string;
    admin: boolean;

    usuario?:any;

    constructor() {
        this.id = null;
        this.nome = null;
        this.perfil = null;
        this.admin = null;
        
        this.usuario = null;
    }
}