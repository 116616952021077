import { Injectable } from '@angular/core';
import { Response } from './../models/response.model';
import { Token } from './../models/token.model';
import { CanActivate, Router } from '@angular/router';
import { AuthBaseService } from 'src/app/services/auth/auth-base.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authBaseService: AuthBaseService) {
    }

    canActivate(): Promise<boolean> {
        return new Promise((resolve) => {

            this.authBaseService.getPerfilLogado(true).then((res: Response<Token>) => {
                if (res.retorno) {
                    resolve(true);
                } else {
                    this.router.navigate(['/leadership-home']);
                    resolve(false);
                }
            }).catch((err) => {
                this.router.navigate(['/leadership-home']);
                resolve(false);
            })
        })
    }
}
