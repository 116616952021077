import { Response } from '../../models/response.model';
import { Token } from '../../models/token.model';
import { AuthBaseService } from 'src/app/services/auth/auth-base.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { first, map, take } from 'rxjs/operators';


@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {

    token: string;
    constructor(
        private authBaseService: AuthBaseService
    ) {
        this.authBaseService.usuarioLogadoSubject.subscribe((res: Token) => {
            if (res) {
                this.token = res.id;
            }
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        var authReq = null;
        if (this.token) {
            authReq = req.clone({
                setHeaders: {
                    'Authorization': this.token,
                    'Content-Type': 'application/json',
                    'APP_KEY': environment.APP_KEY
                }
            })
            // headers = headers.set('Authorization', this.token);
            // authReq = req.clone({ headers: headers });
        }
        else {

            authReq = req.clone({});
        }

        return next.handle(authReq);
    }
}