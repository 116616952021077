export class Popup {
    base64Anexo: string;
    id: number | null;
    link: string;
    mensagem: string;
    nome: string;
    pathAnexo: string;
    popupCampos: PopupCampos[];
    typeFile: string;
    url: string;
    nomeBotaoCustomizado: string;
    ativo: boolean;

    constructor(model?: Popup) {
        this.base64Anexo = model ? model.base64Anexo : '';
        this.id = model ? model.id : null;
        this.link = model ? model.link : '';
        this.mensagem = model ? model.mensagem : '';
        this.nome = model ? model.nome : '';
        this.pathAnexo = model ? model.pathAnexo : '';
        this.popupCampos = model ? model.popupCampos : [];
        this.typeFile = model ? model.typeFile : '';
        this.url = model ? model.url : '';
        this.nomeBotaoCustomizado = model && model.nomeBotaoCustomizado ? model.nomeBotaoCustomizado : 'Enviar';
        this.ativo = model ? model.ativo : false;
    }
}

export class PopupCampos {
    campoFormulario: { id: number, nome: string };
    id: number;
    idCampo: number;
    idPopup: number;
}