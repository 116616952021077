import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faArrowRight, faTag, faArrowLeft, faAnchor } from '@fortawesome/free-solid-svg-icons';
import { faBookmark, faComment, faHeart, faBell, faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faFacebookF, faLinkedin, faInstagram, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
@NgModule({
  imports: [
    FontAwesomeModule
  ],
  exports: [
    FontAwesomeModule
  ],
})
export class FontAwesomeBaseModule {
  constructor(
    private lib: FaIconLibrary
  ) {
    lib.addIcons(faArrowRight, faLinkedinIn, faTag, faArrowLeft, faHeart, faBell, faBookmark, faLightbulb, faComment, faTwitter, faFacebookF, faLinkedin, faInstagram, faYoutube, faAnchor);
  }
}